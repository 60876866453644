import React from 'react'
import parse from 'html-react-parser'
import { getImage } from 'gatsby-plugin-image'

import BackgroundShape from '../../BackgroundShape'

import { BlockTitleSection, BlockTitleTitle, BlockTitleSubtitle, Image } from './BlockTitle.styled'

const BlockTitle = data => {
	if (!data.text && data.data) {
		data = data.data
	}

	const image = getImage(data.image?.localFile)

	return (
		<BlockTitleSection
			paddingBottom={data.paddingBottom}
			paddingTop={data.paddingTop}
			background={data.backgroundShape}
			topOffset={data.topOffset}
			bottomOffset={data.bottomOffset}
		>
			{data.image && <Image image={image} alt={data.image?.altText} isFloating={data.imageIsFloating} />}
			{data.text && <BlockTitleTitle>{parse(data.text)}</BlockTitleTitle>}
			{data.subtext && <BlockTitleSubtitle>{parse(data.subtext)}</BlockTitleSubtitle>}
			{/* {data.backgroundShape && <BackgroundShape paddingBottom={data.paddingBottom} paddingTop={data.paddingTop} />} */}
		</BlockTitleSection>
	)
}

export default BlockTitle
