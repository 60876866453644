import styled, { keyframes } from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import Section from '../../Section'
import TitleBold from '../../TitleBold'

const BlockTitleSection = styled(Section)`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	bottom: ${props => (props.bottomOffset ? props.bottomOffset + 'px' : 'unset')};
	top: ${props => (props.topOffset ? props.topOffset + 'px' : 'unset')};

	&::after {
		content: '';
		display: block;
		background: ${props => (props.background ? '#b0bec5' : 'transparent')};
		width: 100vw;
		position: absolute;
		padding-top: ${props => props.paddingTop}px;
		padding-bottom: ${props => props.paddingBottom}px;
	}
`

const BlockTitleTitle = styled(TitleBold)`
	text-align: center;
	text-transform: uppercase;
	max-width: 100%;
	z-index: 2;
	margin: 0;
	padding-bottom: 10px;
`
const BlockTitleSubtitle = styled.div`
	z-index: 2;
	text-align: center;

	p {
		margin-bottom: 0;
	}
`

const float = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
`

const Image = styled(GatsbyImage)`
	z-index: 2;
	max-width: 320px;
	width: 100%;
	animation: ${float} 3s ease-in-out infinite;

	${props =>
		props.imageIsFloating &&
		`
    animation: ${float} 3s ease-in-out infinite;
  `}

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		max-width: 350px;
	}
`

export { BlockTitleSection, BlockTitleTitle, BlockTitleSubtitle, Image }
