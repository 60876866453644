import React from 'react'

import LinkOrButton from '../../LinkOrButton'

import { BlockCallToActionSection, BackgroundOpacity, Content, Title, Text } from './BlockCallToAction.styled'

const BlockCallToAction = data => {
	if (!data.callToAction && data.data) {
		data = data.data
	}
	const { postContent } = data.callToAction

	// const imageData = postContent?.image?.localFile?.childImageSharp?.fluid?.src
	const imageData = postContent?.image.sourceUrl

	return (
		<BlockCallToActionSection
			Tag="section"
			backgroundImage={imageData}
			paddingTop={postContent?.paddingTop}
			paddingBottom={postContent?.paddingBottom}
		>
			<BackgroundOpacity color={postContent?.imageOverlay} opacity={postContent?.imageOverlayOpacity} />
			<Content>
				<Title>{data.callToAction.title}</Title>
				{postContent.text && <Text dangerouslySetInnerHTML={{ __html: postContent.text }} />}
				<LinkOrButton type={'button'} url={postContent?.buttonLink.url} text={postContent?.buttonText} />
			</Content>
		</BlockCallToActionSection>
	)
}

export default BlockCallToAction
