import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import { transparentize } from 'polished'

const Hero = ({ isHomePage, heroChoice, localImageFile, videoUrl, backgroundColor, backgroundOpacity, heroText }) => {
	const image = getImage(localImageFile)
	return (
		<>
			{heroChoice == `image` ? (
				<HeroImage image={image} alt={`hero-image`} />
			) : (
				<HeroVideo autoPlay loop muted playsInline alt={`hero-video`}>
					<source src={videoUrl} />
				</HeroVideo>
			)}

			{backgroundOpacity && backgroundColor && <HeroOverlay opacity={backgroundOpacity} color={backgroundColor} />}

			<HeroTextWrapper>
				<HeroText isHomePage={isHomePage}>{parse(heroText)}</HeroText>
			</HeroTextWrapper>
		</>
	)
}

const HeroImage = styled(GatsbyImage)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	img {
		min-width: 100%;
	}
`

const HeroOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: ${props => transparentize(1 - props.opacity, props.color)};
`

const HeroTextWrapper = styled.div`
	position: absolute;
	top: 3rem;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	z-index: 15;
`

const HeroText = styled.h1`
	font-family: ${props => props.theme.fonts.big};
	color: white;
	font-size: 3rem;
	margin: ${({ isHomePage }) => (isHomePage ? '0 0 0 10vw ' : 'auto')};
	max-width: 75rem;

	@media (max-width: ${props => props.theme.sizes.maxDesktop}) {
		font-size: 2.5rem;
	}
	@media (max-width: ${props => props.theme.sizes.maxMobile}) {
		font-size: 10vw;
		text-align: center;
	}
`

const HeroVideo = styled.video.attrs({
	autoplay: true
})`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
`

export default Hero
